import React from 'react'
import { useState, useEffect } from 'react'
import GlobalFunctions from '../services/GlobalFunctions'

const TablaGastos = (params) => {
    const glob= new GlobalFunctions()
    const [datos, setDatos]= useState([])

    useEffect(()=>{
        if(datos.length===0){
          if(datos.length!==params.datos.length){
            setDatos(params.datos)
          }
        }
      })

  return (
    <div className='table-responsive'>
        <table className="table table-striped">
        <thead>
                <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Comentarios</th>
                </tr>
        </thead>
        <tbody>
        {params.datos.map((item, index)=>{
        return(
            <tr key={index}>  
                <th scope="row">
                    {item.otros}
                    <br/>
                    <span onClick={()=>params.borrar(item)} className='border border-dark rounded cursorPointer' style={{padding: '0.2em', backgroundColor: 'red', display: item.precio===0 ? 'none' : 'inline'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                    </span>
                </th>
                <td>{item.categoria}</td>
                <td>{glob.formatNumber(item.precio)}</td>
                <td>{item.descripcion}</td>
            </tr>
        )
        })}
        </tbody>
        </table>
  </div>
  )
}

export default TablaGastos