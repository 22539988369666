import GlobalFunctions from './GlobalFunctions'

class DatosService {
    glob= new GlobalFunctions()
  
    getDatos = async () => {
      const url = this.glob.URL_SERV+'/getDatos.php?modo=datosGenerales'
      const respuesta = await fetch(url);
      const data = await respuesta.json();   
      return data;
    }

    getCarteras = async () => {
      const url = this.glob.URL_SERV+'/getCarteras.php?modo=getAll'
      const respuesta = await fetch(url);
      const data = await respuesta.json();   
      return data;
    }
  
  }
  
  export default DatosService