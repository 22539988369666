import React from 'react'

const DialogoEliminar = (params) => {

  return (
    <div className="modal fade" id="dialogoEliminar" tabIndex="-1" aria-labelledby="exampleModalLabel"   aria-hidden="true">
      <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">{params.titulo}</h5>
            </div>
                <div className="modal-footer">
                    <button type="button" style={{display: params.displayBtnCancelar}} className="btn btn-secondary" onClick={params.accionCancelar} >Cancelar</button>
                    <button type="button" onClick={params.accionConfirmar} className="btn btn-danger">{params.textoConfirmar}</button>
                </div>
          </div>
      </div>
  </div>
  )
}

export default DialogoEliminar