import React from 'react'
import { useState, useEffect } from 'react';
import DialogoEliminar from '../UIGeneral/DialogoEliminar';
import NuevaCategoriaGasto from './NuevaCategoriaGasto';
import GastosService from '../services/GastosService';

const CategoriasGastos = (params) => {
    const gastosService = new GastosService()
    const [categorias, setCategorias] = useState([])
    const [mostrarLoading, setMostrarLoading] = useState('none')
    const [message, setMessage] = useState('')
    const [cate, setCate] = useState({
      codigo: '',
      nombre: ''
    })

    useEffect(() => {
          if(params.categoriasGastos.length!==categorias.length){
             setCategorias(params.categoriasGastos)
          } 
     })

    function accionCancelar(){
        document.getElementById('btnDialogoEliminar').click()      
    } 

    function accionConfirmarEliminarCate(){
        document.getElementById('btnDialogoEliminar').click()
        setMostrarLoading('')
        gastosService.eliminarCategoriasGastos(cate.codigo).then((data)=>{
            setCate((valores) => ({
                ...valores,
                codigo: ''
            }))
            setMostrarLoading('none')
            if(data==='Hallazgos'){
                setMessage('Categoria incluida en registros de gastos!')
            }else{
                setMessage('Categoria eliminada!')
                document.getElementById('inputReiniciarCates').click()
            }
        })
    }

    function abrirDialogoborrarCate(id){
        setCate((valores) => ({
            ...valores,
            codigo: id
        }))
        document.getElementById('btnDialogoEliminar').click()
    }

    function cerrarDialogoNuevaCategoria(){
       document.getElementById('btnNuevaCategoria').click()
    }

    function validarDatosCategoria(datos){
        cerrarDialogoNuevaCategoria()
        if(datos===''){
            setMessage('Falta nombre categoria!')
        }else{
            setMessage('')
            validarFetch(datos)
        }
    }

    function validarFetch(datos){
        if(cate.codigo===''){
            fetchCrearCategoria(datos)
        }
    }

    function fetchCrearCategoria(datos){
        setMostrarLoading('')
        gastosService.crearCategoriasGastos(datos).then((data) => {
            setMostrarLoading('none')
            if(data==='Creada'){
              setMessage('Categoria creada!')
              document.getElementById('inputReiniciarCates').click()
            }else{
              setMessage('Ha ocurrido un error al crear la categoria...')  
            }
        })
    }


  return (
    <div className="modal fade bd-example-modal-lg" id='dialogoCategorias' tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">   
    <div className="modal-dialog modal-lg"> 
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Categorias gasto</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
         </div> 
            <div className='container' style={{margin: '0.2em'}}>    
                <div className='container table-responsive'>
                    <input type='hidden' onClick={params.reiniciarCategorias} id='inputReiniciarCates' />
                    <div style={{marginTop: '0.2em'}} align="center" className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12" >
                            <button type="button" id='btnNuevaCategoria' className='btn btn-success btn-sm' data-toggle="modal" data-target="#dialogoNuevaCategoriaGasto">
                                Nueva categoria
                            </button> 
                            <NuevaCategoriaGasto accionCancelar={cerrarDialogoNuevaCategoria} accionConfirmar={validarDatosCategoria}/> 
                        </div>
                        <div style={{marginTop: '0.2em'}} className="col-lg-6 col-md-6 col-sm-12 col-12" >
                            <span id='loadingCategoria' style={{display: mostrarLoading}} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span style={{color: 'red'}}>{message}</span>            
                        </div>  
                    </div>
                    <table className="table table-striped">
                        <thead>
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Categoria</th>
                                </tr>
                        </thead>
                        <tbody>
                            {categorias.map((item, index)=>{
                                return(
                                <tr key={index}>
                                    <th scope="row">
                                    {item.codigo}
                                    <span onClick={()=>abrirDialogoborrarCate(item.codigo)} className='border border-dark rounded cursorPointer' style={{padding: '0.2em', backgroundColor: 'red', marginLeft:'0.2em'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                        </svg>
                                    </span>
                                    </th>
                                    <td>{item.nombre}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <button type="button" id='btnDialogoEliminar'  style={{display: 'none'}} data-toggle="modal" data-target="#dialogoEliminar"></button>
                    <DialogoEliminar titulo={'¿Eliminar esta categoria?'} displayBtnCancelar={'inline'} textoConfirmar={'Si, eliminar.'} accionCancelar={accionCancelar} accionConfirmar={accionConfirmarEliminarCate}/>        
                </div>
            </div>
        </div>
     </div>
    </div>
  )
}

export default CategoriasGastos