import React from 'react'
import { useState, useEffect } from 'react';
import GastosService from '../services/GastosService';
import GlobalFunctions from '../services/GlobalFunctions'
import SelectCategoriasNew from './SelectCategoriasNew';

const NuevoGasto = (params) => {
    const gastosService = new GastosService()
    const glob= new GlobalFunctions()
    const [gasto, setGasto]= useState({
        codigo: '',
        fecha: '',
        categoriaGasto: '',
        nombreCategoria:'',
        valor: 0,
        comentario: '',
        cartera: ''
    })
    const [categoriasGastos, setCategoriasGasto]= useState([])
    const [message, setMessage] = useState('')

    useEffect(() => {
      fechaHoy()
    },[])

    useEffect(() => {
         // Cargar categorias para el select
         if(params.categoriasGastos.length!==categoriasGastos.length){
            setCategoriasGasto(params.categoriasGastos)
         } 
            // Inicializar categoria
            if(gasto.categoriaGasto==='' && categoriasGastos.length>0){
                setGasto((valores) => ({
                    ...valores,
                    categoriaGasto: categoriasGastos[0].codigo,
                    nombreCategoria: categoriasGastos[0].nombre,
                    cartera: params.cartera
                })) 
            }
        // Cargar cartera
        if(params.cartera!==gasto.cartera){
            setGasto((valores) => ({
                ...valores,
                cartera: params.cartera
            }))
        }    
    })

    function cambioValor(e){
        setGasto((valores) => ({
            ...valores,
            valor: e.target.value
        }))
    }

    function cambioComentario(e){
        setGasto((valores) => ({
            ...valores,
            comentario: e.target.value
        }))
    }

    function fechaHoy() {
          setTimeout(() => {
            if(gasto.fecha===''){
              setGasto((valores) => ({
                ...valores,
                fecha: glob.getFecha()
              }))
            }
          }, 100);
    }

    function loadingOn(){
        document.getElementById('btnIngresar').style.display='none'
        document.getElementById('btnLoading').style.display='inline'  
    }

    function loadingOff(){
        document.getElementById('btnIngresar').style.display='inline'
        document.getElementById('btnLoading').style.display='none'
    }

    function fetchNuevoGasto(){
        if(gasto.valor===0 || gasto.categoriaGasto===''){
            setMessage('Faltan datos importantes!')
        }else{
            setMessage('')
            loadingOn()
            gastosService.ingresarGastos(gasto).then((data)=>{
                loadingOff()
                if(data=='Gasto ingresado'){
                    document.getElementById('inputReiniciarGastos').click()
                    setGasto((valores) => ({
                        ...valores,
                        categoriaGasto: '',
                        valor: 0,
                        comentario: ''
                    }))
                }else{
                    alert('Ha ocurrido un error al ingresar el gasto!')
                }
            }) 
        }
    }

    function cambioFecha(e){
        setGasto((valores) => ({
            ...valores,
            fecha: e.target.value
        }))
    }

    function cambioCategoria(e){
        const filter= categoriasGastos.filter((art)=>art.codigo === e.target.value)
        setGasto((valores) => ({
            ...valores,
            categoriaGasto: e.target.value,
            nombreCategoria: filter[0].nombre
        }))
    }

  return (
    <div className="modal fade bd-example-modal-lg" id='dialogoNuevoGasto' tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <input type='hidden' id='inputReiniciarGastos' onClick={params.reiniciarGastos} />    
        <div className="modal-dialog modal-lg"> 
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Nuevo gasto</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> 
                <div className='container' style={{margin: '0.2em'}}>
                    <span style={{color: 'red', display: message==='' ? 'none': 'inline'}}>{message}</span> 
                    <br/>
                    <label>Fecha:</label>
                    <br/>
                    <input type="date" value={gasto.fecha} className='form-control' onChange={cambioFecha} name="fecha_prest" id="inputDate"/>
                    <br/>
                    <label style={{marginTop: '0.2em'}}>Selecciona categoria:</label>
                    <SelectCategoriasNew getCategoria={cambioCategoria} categorias={categoriasGastos} />
                    <label style={{marginTop: '0.2em'}}>Categoria seleccionada:</label>
                    <input type={'text'} className='form-control' disabled value={gasto.nombreCategoria}></input>
                    <br/>    
                    <input onChange={cambioValor} className='form-control' type="number" placeholder='Valor gasto' value={gasto.valor}/>
                    <br/>
                    <textarea onChange={cambioComentario} className='form-control' placeholder='Comentarios' value={gasto.comentario}></textarea>
                        <div style={{marginTop: '0.4em'}} className="col text-center">
                            <button onClick={fetchNuevoGasto} id="btnIngresar" style={{display:'inline'}} className="btn btn-success">Ingresar gasto</button>
                            <button id='btnLoading' style={{display:'none', backgroundColor:'red'}} className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                            </button>
                        </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NuevoGasto