import React from 'react'
import { useState, useEffect } from 'react';

const NuevaCategoriaGasto = (params) => {
    const [nombre, setNombre] = useState('')

    function cambioNombre(e){
      setNombre(e.target.value)
    }

    function confirmar(){
        document.getElementById('irCategorias').click()
        setNombre('')
    }

  return (
    <div className="modal fade bd-example-modal-lg" id='dialogoNuevaCategoriaGasto' tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">   
        <div className="modal-dialog"> 
            <input id='irCategorias' onClick={()=>params.accionConfirmar(nombre)}></input>
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Nueva categoria</h5>
            </div> 
                <div className='container' style={{margin: '0.2em'}}>   
                    <input onChange={cambioNombre} className='form-control' type="text" value={nombre} placeholder='Nombre categoria'/>
                    <br/>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={params.accionCancelar} >Cancelar</button>
                        <button type="button" className="btn btn-success"  onClick={confirmar}>Crear categoria</button>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NuevaCategoriaGasto