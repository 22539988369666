import GlobalFunctions from './GlobalFunctions'

class GastosService {

  glob= new GlobalFunctions()

  async getGastos(fechas, categoria, cartera) {
      const url = this.glob.URL_SERV+'/getGastos.php?modo=getGastos&fechaInicial='+fechas.fechaInicio+"&fechaFinal="+fechas.fechaFinal+"&categoria="+categoria+"&cartera="+cartera
      let response = await fetch(url);
      let data = await response.json();
      return data;
  }

  async getCategoriasGastos() {
    const url = this.glob.URL_SERV+'/getGastos.php?modo=getCategorias'
    let response = await fetch(url);
    let data = await response.json();
    return data;
  }

  async crearCategoriasGastos(nombre) {
    const url = this.glob.URL_SERV+'/getGastos.php?modo=crearCateGasto&nombre='+nombre
    let response = await fetch(url);
    let data = await response.json();
    return data;
  }

  async eliminarCategoriasGastos(id) {
    const url = this.glob.URL_SERV+'/getGastos.php?modo=eliminarCateGasto&id='+id
    let response = await fetch(url);
    let data = await response.json();
    return data;
  }

  async ingresarGastos(datos) {
    const url = this.glob.URL_SERV+'/getGastos.php?modo=ingresarGasto'
    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(datos),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    let data = await response.json();
    return data;
  }

  async eliminarGasto(id) {
    const url = this.glob.URL_SERV+'/getGastos.php?modo=eliminarGasto&id='+id
    let response = await fetch(url);
    let data = await response.json();
    return data;
  }

}

export default GastosService