import React from 'react';
import {BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import './css/general.css'
import Gastos from './gastos/Gastos'
import DatosService from './services/DatosService'
import { useState, useEffect } from 'react';
import { Select } from '@mui/material';
import Cargando from './UIGeneral/Cargando'
import GlobalFunctions from './services/GlobalFunctions'

function App() {
  const glob= new GlobalFunctions()
  const [datos, setDatos]= useState({})
  const datosServices= new DatosService()
  const [carteras, setCarteras]= useState([])

  useEffect(()=>{
    if(Object.keys(datos).length==0){
       datosServices.getDatos().then(datos=>setDatos(datos))
       datosServices.getCarteras().then(datos=>setCarteras(datos))
    }
  })

  useEffect(()=>{  
    if(Object.keys(datos).length>0){
        if(datos.referencia!==glob.getCookie('versionApp')){ 
           glob.setCookie('versionApp', datos.referencia, glob.setExpires('1'))
           borrarCache()
       }
    }
  }, [datos])
console.log(datos.otros)
   // function para forzar cache y actualizar app
 function borrarCache(){
    if('caches' in window){
    caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });
        window.location.reload(true);
    }
  }

  if(carteras.length>0){
    return (
        <Router>
            <nav className="navbar navbar-expand-md fondoRojo">
                <div  className="container">	
                    <button id='btnToggler'  className="navbar-toggler" type="button" data-toggle="collapse" data-target="#opciones">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </button> 
                    <a href={datos.otros} className="navbar-brand cursorPointer"  >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-house-door colorBlanco" viewBox="0 0 16 16">
                            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
                        </svg>
                        <span style={{marginLeft: '0.2em'}} className='colorBlanco fontSizeNormal'>Lobby</span>
                    </a>
                    <Select placeholder='Buscar cliente'/>
                    <div className="collapse navbar-collapse" id="opciones">
                            <ul className="navbar-nav centrarAuto">
                                <li className="nav-item">
                                    <Link className="nav-link colorBlanco fontSizeNormal" id='linkGastos' to={"/gastos/"+carteras[0].nombre}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-compact-down colorBlanco" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"/>
                                        </svg>
                                        <span style={{marginLeft: '0.2em'}} className='colorBlanco fontSizeNormal'>Gastos</span>
                                    </Link> 
                                </li> 
                            </ul> 
        
                    </div>        
                </div>
            </nav>
            <Routes>
                <Route path="/gastos/:cartera" element={<Gastos carteras={carteras}/>} /> 
            </Routes>    
        </Router>
      )
  }else{
    return(
        <div style={{textAlign: 'center'}}>
            <Cargando />
        </div>  
    )
  }
 
}

export default App;
