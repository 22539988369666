import React from 'react'
import { useState, useEffect } from 'react';
import GastosService from '../services/GastosService';
import TablaGastos from './TablaGastos';
import NuevoGasto from './NuevoGasto';
import CategoriasGastos from './CategoriasGastos';
import DialogoEliminarGasto from './DialogoEliminarGasto';
import GlobalFunctions from '../services/GlobalFunctions'
import SelectCategorias from './SelectCategorias';
import { useParams } from 'react-router-dom'

const Gastos = (params) => {
  const urlParams = useParams()
  const glob= new GlobalFunctions()
  const gastosService = new GastosService()
  const [datos, setDatos]= useState([])
  const [categoriasGastos, setCategoriasGasto]= useState([])
  const [mostrarMensaje, setMostrarMensaje]= useState('none')
  const [mensaje, setMensaje]= useState('')
  const [cargar, setCargar]= useState(true)
  const [cargarCategorias, setCargarCategorias]= useState(true)
  const [gastoBorrar, setGastoBorrar]= useState('')
  const [fechas, setFechas]= useState({
    fechaInicio: '',
    fechaFinal: ''
  })
  const [total, setTotal]= useState(0)
  const [filtrarCategoria, setFiltrarCategoria]= useState('')
  const [carteras, setCarteras]= useState([])
  const [carteraSeleccionada, setCarteraSeleccionada]= useState('')

  useEffect(()=>{
    if(cargar && fechas.fechaInicio!=='' && carteraSeleccionada!==''){
        getGastos()
    }
  }, [datos, fechas, filtrarCategoria, carteraSeleccionada])
  
  useEffect(()=>{
      if(cargarCategorias){
         getCategorias()
      }
  }, [categoriasGastos])

  useEffect(() => {
    cargarFechas()
  },[])

  useEffect(() => {
    if(params.carteras.length!==carteras.length){
       setCarteras(params.carteras) 
    }
  })

  useEffect(() => {
    validarParametroCartera()
  },[carteras])

  function validarParametroCartera(){
    if(carteraSeleccionada==='' && carteras.length>0){
      const filter =carteras.filter(cod => cod.nombre===urlParams.cartera)
      setCarteraSeleccionada(filter[0].codigo)
    }
  }

  function cargarFechas(){
    const fechaHoy= glob.getFecha().split("-")
    // Para obtener ultimo dia del mes.
    const date=new Date(fechaHoy[0], fechaHoy[1], 0).toLocaleDateString("en-US")
    const ultimoDia=date.split('/')
    setFechas({
      fechaInicio: fechaHoy[0]+"-"+fechaHoy[1]+"-01",
      fechaFinal: fechaHoy[0]+"-"+fechaHoy[1]+"-"+ultimoDia[1]
    })
  }

  function getCategorias(){
    gastosService.getCategoriasGastos().then((data) => {
      setCargarCategorias(false)
      setCategoriasGasto(data)
    });
  }

  function borrar(item){
     setGastoBorrar(item.codigo)
     document.getElementById('btnDialogoEliminarGasto').click()
  }

  const getGastos = () => {
      gastosService.getGastos(fechas, filtrarCategoria, carteraSeleccionada).then((data) => {
         setCargar(false)
         if(data.length===0){
            const array =[]
            const objeto={
              categoria: 'No hay gastos registrados!',
              precio: 0  
            }
            array.push(objeto)
            setDatos(array)
         }else{
           setDatos(data)
           calcularTotal(data)
         }    
      }); 
  }

  function calcularTotal(data){
    let subtotal=0
    for(let i=0; i<data.length; i++){
      subtotal=subtotal+parseInt(data[i].precio)
    }
    setTotal(subtotal)
  }

  function nuevoGasto(){
    document.getElementById('btnDialogoNuevaGasto').click()
  }

  function irCategorias(){
      document.getElementById('btnDialogoCategorias').click()
  }

  function reiniciarCategorias(){
    const array=[]
    setCategoriasGasto(array)
    setCargarCategorias(true)
    getCategorias()
  }

  function reiniciarGastosNuevoGasto(){
    document.getElementById('btnDialogoNuevaGasto').click()
    reiniciarGastos()
  }

  function reiniciarGastos(){
    const array=[]
    setDatos(array)
    getGastos()
    setMensaje('')
    setMostrarMensaje('none')
  }

  function accionCancelar(){
    document.getElementById('btnDialogoEliminarGasto').click()
  }

  function accionConfirmarEliminar(){
    const array=[]
    setDatos(array)
    document.getElementById('btnDialogoEliminarGasto').click()
    gastosService.eliminarGasto(gastoBorrar).then((data)=>{
       if(data==='Gasto eliminado'){
        getGastos()
        setMensaje('Gasto eliminado!')
        setMostrarMensaje('inline')
       }else{
        alert('Problemas al eliminar gasto!')
       }
    })
  }

  function cambioFechaInicio(e){
    setCargar(true)
    setFechas((valores) => ({
      ...valores,
      fechaInicio: e.target.value
    }))  
  }

  function cambioFechaFinal(e){
    setCargar(true)
    setFechas((valores) => ({
      ...valores,
      fechaFinal: e.target.value
    }))
  }

  function cambioCategoria(e){
    setTotal(0)
    setCargar(true)
    setFiltrarCategoria(e.target.value)
  }

  function reiniciarFiltrarCategoria(){
    setCargar(true)
    setFiltrarCategoria('')
  }

  function cambioCartera(e){
    setCargar(true)
    setCarteraSeleccionada(e.target.value)
  }

  return (
    <div className='container'>
        <div  style={{marginTop: '0.2em'}} align="center" className="row justify-content-center">
          <div className="col-4" >
            <a onClick={nuevoGasto} className='btn btn-success btn-sm'>Nuevo gasto</a> 
          </div>
          <div style={{marginTop: '0.2em'}} className="col-4" >
              <span style={{display: mostrarMensaje, color: 'red'}}>{mensaje}</span>
              <span style={{display: cargar ? '' : 'none'}} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </div> 
          <div className="col-4">
            <a onClick={irCategorias} className='btn btn-success btn-sm'>Categorias</a> 
          </div> 
      </div>

      <div className="row justify-content-center">

        <div className='row col-lg-6 col-md-6 col-sm-12 col-12'>
          <label style={{textAlign: 'center'}}><strong>Gastos entre</strong></label>
          <div className="col-6">
            <input type="date" className='form-control' value={fechas.fechaInicio} onChange={cambioFechaInicio} name="fecha_prest" id="inputDate"/> 
          </div>
          <div className="col-6" >
            <input type="date" className='form-control' value={fechas.fechaFinal} onChange={cambioFechaFinal} name="fecha_prest" id="inputDate"/> 
          </div>
        </div>
        
        <div style={{textAlign:'center'}} className="col-lg-6 col-md-6 col-sm-12 col-12" >
          <label>
            <strong>Selecciona cartera:</strong>
            <select onChange={cambioCartera} className="form-select" aria-label="Default select example" value={carteraSeleccionada} >
              {carteras.map((item, index)=>{
                return(
                  <option key={index} value={item.codigo}>{item.nombre}</option> 
                )
              })}
            </select>
          </label>

        </div> 
      </div> 

      <div  style={{marginTop: '0.4em'}} className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12" >
            <div className='justify-content-center input-group col-12'>
              <br/>
              <SelectCategorias id='selectCategorias' getCategoria={cambioCategoria} categorias={categoriasGastos} />
                <button onClick={reiniciarFiltrarCategoria} className='border border-dark rounded cursorPointer' style={{marginLeft: '0.2em', padding: '0.3em', backgroundColor: 'red'}}  id="btn_buscar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                  </svg>
                </button>
            </div>                                                        
          </div>
          <div style={{textAlign: 'center'}} className="col-lg-6 col-md-6 col-sm-12 col-12">
            <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Total</th>
                    <th scope="col">{glob.formatNumber(total)}</th>
                  </tr>
                </thead>
              </table>
          </div> 
      </div> 
      <TablaGastos borrar={borrar} datos={datos}/>
      <button type="button" id='btnDialogoEliminarGasto'  style={{display: 'none'}} data-toggle="modal" data-target="#dialogoEliminarGasto"></button>
      <DialogoEliminarGasto titulo={'¿Eliminar registro?'} displayBtnCancelar={'inline'} textoConfirmar={'Si, eliminar.'} accionCancelar={accionCancelar} accionConfirmar={accionConfirmarEliminar}/> 
      <button type="button" id='btnDialogoNuevaGasto'  style={{display: 'none'}} data-toggle="modal" data-target="#dialogoNuevoGasto"></button>
      <NuevoGasto cartera={carteraSeleccionada} reiniciarGastos={reiniciarGastosNuevoGasto} categoriasGastos={categoriasGastos}/>
      <button type="button" id='btnDialogoCategorias'  style={{display: 'none'}} data-toggle="modal" data-target="#dialogoCategorias"></button>
      <CategoriasGastos reiniciarCategorias={reiniciarCategorias} categoriasGastos={categoriasGastos} />
    </div>  
  )
}

export default Gastos